import React from "react"
import { Layout } from "../components/Layout"
import {  Text, Box, Stack } from "../common"
import { Collapse } from "antd"
import { Link } from "gatsby"
import { css } from "@emotion/core"
import { ZHelmet } from "../components/ZHelmet"

const FAQ_DATA = [
  {
    title: "How do I contact Zavron Finance Private Limited?",
    content: (
      <Text variant="p">
        You can contact Zavron Finance Private Limited at - +91-8447723591 for your
        loan queries or EMI queries. You can write to us at{" "}
        <a href="mailto:info@zavronfinserv.com">info@zavronfinserv.com</a>.
      </Text>
    ),
  },
  {
    title: "How do I apply for a new loan?",
    content: (
      <Text variant="p">
        You can apply for Personal Loan, Business Loan, Two-wheeler Loan, or
        Used Vehicle Loan on our website here.
        {/* You can download our app ZCash and apply for instant loans. */}
      </Text>
    ),
  },
  {
    title: "How can I check my Loan status?",
    content: (
      <Text variant="p">
        You can go to our app and login to check your loan status or simply
        write to us at{" "}
        <a href="mailto:info@zavronfinserv.com">info@zavronfinserv.com</a> and
        we will get back to you.
      </Text>
    ),
  },
  {
    title: "Where can I check loan eligibility?",
    content: (
      <Text variant="p">
        You can check <Link to="/products/personal-loan">Personal Loan</Link>,{" "}
        <Link to="/products/personal-loan">Business Loan</Link>,{" "}
        <Link to="/products/personal-loan">Two-wheeler Loan</Link>,{" "}
        <Link to="/products/personal-loan">Used Car Loan</Link>, page to check
        eligibility.
      </Text>
    ),
  },
  {
    title:
      "I have income apart from my salary, will it be considered for eligibility?",
    content: (
      <Text variant="p">
        Yes, it can be considered if proper documentation is available and other
        credit parameters get fulfilled.
      </Text>
    ),
  },
  {
    title: "Do I have to submit any collateral?",
    content: (
      <Text variant="p">
        You will not be required to put your personal or business assets as
        collateral for loans.
      </Text>
    ),
  },
  {
    title: "How much time will it take to complete the loan process?",
    content: (
      <Text variant="p">
        Depending on the evaluation, your loan request will be processed within
        2 days’ time.
      </Text>
    ),
  },
  {
    title: "What documents are required for loan application?",
    content: (
      <Stack space="var(--s-4)">
        <Text variant="p">
          The basic documents required for loan application are:
        </Text>
        <ul
          css={css`
            padding-left: var(--s1);
            margin-top: var(--s0);
            li {
              margin-bottom: var(--s-1);
            }
          `}
        >
          <li>
            <Text variant="p">Identification card</Text>
          </li>
          <li>
            <Text variant="p">PAN card</Text>
          </li>
          <li>
            <Text variant="p">Address Proof</Text>
          </li>
          <li>
            <Text variant="p">
              Salary slips of last 3 months’ (for salaried employees)
            </Text>
          </li>
          <li>
            <Text variant="p">Income tax return record</Text>
          </li>
        </ul>
        <Text variant="p">
          The requirement for each loan may differ, kindly refer to the loan
          specific pages for the same.
        </Text>
      </Stack>
    ),
  },
  {
    title: "Will my loan be reported to CIBIL/other credit bureaus?",
    content: (
      <Text variant="p">
        Yes, your loan and performance of your loan track will be reported to
        CIBIL, Crif and other credit bureaus; as per the RBI guidelines. A good
        loan repayment ensures building a good credit history and score; at the
        same time a bad loan repayment with delayed payments and over dues -
        adversely affect your credit history and score.
      </Text>
    ),
  },
]

export default function FAQS() {
  return (
    <Layout>
      <ZHelmet
        title="Frequently Asked Questions | Personal Loan | FAQs | NBFC"
        description="Get relevant answers to FAQs related to instant personal loan by Zavron Finserv and apply to avail easy personal finance with quick disbursement on easy EMIs"
      />
      <Stack style={{ padding: "var(--s0) var(--s0)" }} space="var(--s1)">
        <Text style={{ textAlign: "center" }} variant="h2">
          FAQS
        </Text>
        <Box
          style={{
            width: "100%",
            maxWidth: "var(--max-width)",
            marginLeft: "auto",
            marginRight: "auto",
          }}
        >
          <Collapse
            accordion
            expandIconPosition="right"
            css={css`
              p {
                margin: 0 !important;
              }
              .ant-collapse-header {
                padding: var(--s0) var(--s0) !important;
                background: #ededed !important;
                border-radius: 3px;
                margin-bottom: var(--s0) !important;
              }
              .ant-collapse-item-active > .ant-collapse-header {
                margin-bottom: 0 !important;
              }
            `}
            defaultActiveKey={["1"]}
            ghost
          >
            {FAQ_DATA.map((faq, index) => (
              <Collapse.Panel
                key={index + 1}
                header={
                  <Box>
                    <Text variant="h4">{faq.title}</Text>
                  </Box>
                }
              >
                <Box
                  css={css`
                    padding: 0 0 var(--s1) 0 !important;
                    p {
                      font-weight: normal !important;
                      /* color: #666666 !important; */
                    }
                  `}
                >
                  {faq.content}
                </Box>
              </Collapse.Panel>
            ))}
          </Collapse>
        </Box>
      </Stack>
    </Layout>
  )
}
